.Footer-container {
    min-height: 80px;
    width: 100vw;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: #111;

    &_rights {
        text-decoration: none;
        font-size:15px;
        margin-right: 20px;
    }

    &_others {
        a{
            text-decoration: none;
            font-size:15px;
            margin-right: 20px;
            color:white;
        }
    }
}

@media screen and (max-width: 700px) {
    .Footer-container {
        padding: 0 10%;
        flex-direction: column;
        &_rights {
            font-size: 13px;
            margin-right: 5%;
        }

        &_others {
            display: flex;
            a{
                font-size: 13px;
            }
        }
    }
}