body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  color: white;
  font-size: 60px;
  line-height: 0.98em;
  font-weight: 600;
  letter-spacing: -.005em;
  text-shadow: 0px 0px 10px rgba(0,0,0, 0.30);
}

h2 {
  color: white;
  font-size: 40px;
  line-height: 0.98em;
  font-weight: 600;
  letter-spacing: -.005em;
  text-shadow: 0px 0px 10px rgba(0,0,0, 0.30);
}

h4 {
  color: white;
  font-size: 20px;
  font-weight: 700;
  text-shadow: 1px 1px 1px rgba(0,0,0, 0.16);
}

h5 {
  color: rgb(152,152,152);
  font-weight: 400;
  line-height: 1.5em;
  font-size: 18px;
  text-shadow: 1px 1px 1px rgba(0,0,0, 0.16);
}

h6 {
  color: rgb(76,76,76);
  font-weight: 600;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  line-height: 0.98em;
  font-size: 18px;
}

p {
  color: rgb(152,152,152);
  font-weight: 400;
  line-height: 1.5em;
  font-size: 18px;
  text-shadow: 1px 1px 1px rgba(0,0,0, 0.16);
}

a {
  color: white;
}

@media screen and (max-width: 700px) {
  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 30px;
  }

  h4 {
    font-size: 18px;
  }

  h5 {
    font-size: 15px;
  }

  h6 {
    font-size: 13px;
  }

  p {
    font-size: 15px;
  }
}