@import "/src/variables.scss";

.Buy-container {
    width: 100vw;
    background-color: $backgroundColor;
    display: flex;
    justify-content: center;
    padding: 50px 0;
    
    &_center {
        width: 70vw;
        display: flex;
        flex-direction: column;

        &_btnContainer {
            z-index: 1;
            margin-left: auto;
            margin-right: auto;
            margin-top: 30px;

            .btn {
                width: 200px;
                border-radius: 8px;
                z-index: 1;

                img {
                    height: 100%;
                    width: 100%;
                    filter: invert(90%);
                }
            }
        }

        &_img {
            width: 100%;
            text-align: center;
            margin-bottom: 60px;
            img {
                width: 30%;
                max-width: 600px;
                margin-left: -20px;
            }
        }

        &_subscription {
            color:white;
            padding: 10px;
            padding-left: 15px;
            letter-spacing: 1px;
            text-align: center;
            width: 180px;
            margin-top: 20px;
            margin-left: auto;
            margin-right: auto;
            color: rgb(152,152,152);
            font-size: 25px;
            position: relative;
        }

        &_subscription::after {
            border-bottom: 0.07em solid white;
            content: "";
            left: 0;
            margin-top: calc(0.125em / 2 * -1);
            margin-left: 18px;
            margin-right: 13px;
            position: absolute;
            right: 0;
            top: 55%;
        }

        &_onetime {
            border: 1px solid #fff;
            border-radius: 20px;
            color:white;
            padding: 10px;
            padding-left: 15px;
            text-decoration: none;
            text-transform: uppercase;
            letter-spacing: 1px;
            text-align: center;
            width: 200px;
            margin-left: auto;
            margin-right: auto;
        }

        h1 {
            text-align: center;
            padding: 15px 0;
            background: rgb(2,135,227);
            background: linear-gradient(150deg, rgba(2,135,227,1) 30%, rgba(102,54,183,1) 53%, rgba(200,61,115,1) 70%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin: auto;
        }

        .table {
            margin-top: 30px;
            display: flex;
            justify-content: space-evenly;
            flex-wrap: wrap;

            .pack {
                width: 300px;
                text-align: center;
                display: flex;
                margin: 10px 10px;
                flex-direction: column;
                border-radius: 10px;

                .header {
                    position: relative;
                    h2 {
                        font-size: 40px;
                        text-shadow: none;
                        padding-bottom: 10px;
                        color: #000;
                    }
                    padding: 20px;
                    color: #111;

                    background-color: #e1dfdf;
                    border-radius: 10px 10px 0 0;

                    .price {
                        width: 80px;
                        margin-left: auto;
                        margin-right: auto;
                    }

                    .reduction {
                        background-color: orange;
                        position: absolute;
                        right: -20px;
                        top: -20px;
                        border-radius: 8px;
                        padding: 5px;
                        color: #000;
                    }
                }
                background-color: #111111;

                .list {
                    padding: 5px;
                    .item {
                        padding: 10px;
                        color: white;
                    }

                    .item_pack {
                        padding: 10px;
                        color:white;

                        .plus {
                            background-color: white;
                            border-radius: 15px;
                            color:black;
                            width: 30px;
                            height: 30px;
                            font-size: 23px;
                            font-weight: 500;
                            line-height: 25px;
                            margin-left: auto;
                            margin-right: auto;
                        }
                    }
                }
            }

            .full {
                .header {
                    h2 {
                        color:white;
                    }
                    color: white;
                    background-color: #007BFF;
                    border-radius: 10px 10px 0 0;
                    text-align: center;

                    .price {
                        text-decoration: line-through;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1380px) {
    .Buy-container {
        &_center {
            .table {
                .pack {
                    width: 250px;
                }
            }
        }
    }
}

@media screen and (max-width: 1170px) {
    .Buy-container {
        &_center {
            .table {
                .pack {
                    width: 200px;
                    margin: 5px;
                }
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .Buy-container {
        &_center {
            &_img {
                img {
                    width: 80%;
                }
            }
            .table {
                .pack {
                    width: 100%;
                    margin-top: 30px;
                }
                .pack:first-child {
                    margin-top: 0px;
                }
            }
        }
    }
}