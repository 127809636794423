.ipad {
    width: 75vw;
    max-width: 720px;
    display: block;
    position: relative;
    flex-shrink: 0;
    z-index: 2;

    &:before {
        background-image: url("../../assets/mockup.png");
        content: "";
        position: absolute;
        background-size: 100%;
        background-repeat: no-repeat;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 3;
    }

    img {
        display: block;
        overflow: hidden;
        //width: 93.5%;
        //padding: 3.3% 0 4.278%;
        width: 93.5%;
        padding: 3.4% 0 4.278%;
        margin: 0 auto;
    }
}

.Landing-container {
    &:before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgb(255,255,255);
        background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(40,40,42,0.2) 60%, rgba(0,0,0,1) 100%);
        background-size: 100%;
        z-index: 1;
    }

    .Landing-container-center {
        min-height: 400px;
        height: 60vh;
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        padding-top: 30px;

        &_title {
            text-align: center;
            margin: 50px 0;
            z-index: 1;
            padding-left: 15px;
            padding-right: 15px;
        }

        &_video {
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            height: 100%;
            object-fit: cover;
            //filter: saturate(2);
        }

        &_btnContainer {
            z-index: 1;

            .btn {
                width: 200px;
                border-radius: 8px;
                z-index: 1;

                img {
                    height: 100%;
                    width: 100%;
                }
            }
        }



        &_new {
            border: 2px solid #c83d73 ;
            border-radius: 20px;
            color:white;
            padding: 10px;
            padding-left: 15px;
            text-decoration: none;
            text-transform: uppercase;
            letter-spacing: 1px;
            text-align: center;
            margin-bottom: 20px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .Landing-container-iPads {
        position: relative;
        display: flex;
        justify-content: center;
        padding-bottom: 5vh;

        .left {
            width: 43%;
            display: flex;
            position: relative;
            justify-content: flex-end;
            padding: 0 15px 0 15px;
            z-index: 4;
        }

        .right {
            width: 45%;
            display: flex;
            position: relative;
            justify-content: flex-start;
            padding: 7% 15px 0 15px;
            z-index: 5;

            .ipad {
                width: 65vw;
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .ipad {
        max-width: 480px;
    }
    .Landing-container {
        .Landing-container-iPads {
            flex-direction: column;
            transition: all 1s ease-in-out;

            .ipad {
                width: 90vw;
            }

            .left {
                width: 100%;
                justify-content: center;
                transform: translateX(-25px);
            }

            .right {
                justify-content: center;
                width: 100%;
                padding: 0 15px 0 15px;
                transform: translateY(-50px) translateX(75px);

                .ipad {
                    width: 70vw;
                }
            }
        }

        .Landing-container-center {
            height: 20vh;
            min-height: 400px;
            &_btnContainer {
                .btn {
                    height: 52px;
                    width: 150px;
                }
            }

            &_new {
                margin-left: 10px;
                margin-right: 10px;
            }
        }
    }
}