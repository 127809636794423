.Privacy-container {
    .Privacy-container-center {
        min-height: 400px;
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        padding-top: 30px;
        padding-bottom: 30px;

        &_title {
            text-align: center;
            margin: 100px 0 50px 0;
            z-index: 1;
            padding-left: 15px;
            padding-right: 15px;
            background: rgb(2, 135, 227);
            background: linear-gradient(230deg, rgba(93, 248, 254, 1) 40%, rgba(225, 21, 180, 1) 70%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        &_content {
            width: 70vw;

            p {
                margin-bottom: 30px;
            }

            h4 {
                margin-bottom: 10px;
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .Privacy-container {
        Privacy-container-center {

        }
    }
}