@import "/src/variables.scss";

.Cloud-container {
    width: 100vw;
    display: flex;
    justify-content: center;
    padding: 25px 0;
    background-image: url("../../assets/baniere-philips-hue.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;
    
    &_center {
        max-width: 1200px;
        width: 70vw;
        display: flex;
        flex-direction: column;

        .cloud {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            &_img {
                position: relative;
                flex: 2;
                text-align: center;

                .hue_logo {
                    width: 100px;
                    float: right;
                    margin-right: 40px;
                    background-color: #fff;
                    border-radius: 10px;
                    margin-top: -50px;
                }
            }
            &_description {
                flex: 2;
                text-align: left;
                margin-top: 30px;

                h2 {
                    margin-bottom: 40px;
                    margin-top: 40px;
                }

                .text {
                    margin-bottom: 30px;
                    margin-top: 20px;

                    background: rgb(0,180,250);
                    background: linear-gradient(124deg, rgba(0,180,250,1) 0%, rgba(152,152,152,1) 75%, rgba(152,152,152,0) 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;

                    .requirements {
                        color: rgb(152,152,152);
                        font-size: 15px;
                        font-style: italic;
                    }
                }

                .hue_logo {
                    width: 100px;
                    margin-top: 40px;
                    clear: both;
                    display: none;
                    margin-left: auto;
                    margin-right: auto;
                    background-color: #fff;
                    border-radius: 10px;
                }
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .Cloud-container {
        &_center {
            width: 80vw;
            .title {
                font-size: 26px;
            }

            .cloud {
                &_img {
                    display: none;
                }

                &_description {
                    .text {
                        font-size: 16px;
                    }

                    .hue_logo {
                        display: block;
                    }
                }
            }
        }
    }
}