@import "/src/variables.scss";

.MIDI-container {
    width: 100vw;
    display: flex;
    justify-content: center;
    background: #171717;
    
    &_center {
        max-width: 1200px;
        width: 70vw;
        display: flex;
        flex-direction: column;

        .midi_keyboard {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            overflow: hidden;
            &_img {
                flex: 5;
                text-align: center;

                img {
                    width: 75%;
                    margin: 20px auto -20px -240px;
                }
            }
            &_description {
                flex: 2;
                margin-left : -280px;

                h1 {
                    margin-bottom: 40px;
                    font-size: 3.3em;
                    span{
                        background: orange;
                        font-size: 0.35em;
                        border-radius: 8px;
                        padding: 5px;
                        font-weight: normal;
                        vertical-align:middle;
                        color:#000;
                    }
                }

                i{
                    font-size: 0.8em;
                }

                .text {
                    margin-bottom: 50px;
                    margin-top: 20px;
                }
            }
        }
    }
}

@media screen and (max-width: 1220px) {
    .MIDI-container {
        &_center {
            .midi_keyboard {
                &_img {
                    img {
                        width: 60%;
                        margin-top: 60px;
                    }
                }

                &_description {

                    h1 {
                        margin-top:20px;
                        margin-bottom: 20px;

                        font-size: 2.7em;
                    }

                    .text {
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 900px) {

    .MIDI-container {
        &_center {
            width: 80vw;
            text-align: center;

            .midi_keyboard {
                flex-direction: column-reverse;
                &_description {
                    margin:auto;
                    .text {
                        font-size: 16px;
                        margin-bottom: 0px;
                    }

                    h1 {
                        margin: 20px auto;
                    }
                }

                &_img {
                    margin: auto;

                    img{
                        margin: auto auto -10px;
                        width: 100%;
                    }
                }
            }
        }
    }
}