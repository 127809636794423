.SyncMusic-container {
    position: relative;
    width: 100vw;
    display: flex;
    justify-content: center;
    background-image: url("../../assets/dj.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom left;

    &:before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgb(255,255,255);
        background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(40,40,42,0.2) 60%, rgba(0,0,0,1) 100%);
        background-size: 100%;
        z-index: 1;
    }

    &_center {
        max-width: 1200px;
        z-index: 1;
        width: 80vw;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        padding-top: 50px;

        &_left {
            position: relative;
            width: 30vw;
            text-align: center;
            height:100%;
            background-image: url("../../assets/showtime.png");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: bottom right;
        }
        &_right {
            width: 70vw;
            padding: 0 0 50px 0;

            .title {
                margin: 10px auto;
                text-align: center;
            }

            &_illu {
                display: block;
                margin-left: auto;
                margin-right: auto;
                border-radius: 10px;
                width: 40vw;
                max-width: 200px;
                padding: 10px 0;

                background: rgb(25,48,66);
                background: linear-gradient(360deg, #232224 20%, #1e1f1e 28%, #18171a 100%);

                img {
                    width: 100%;
                }

                -moz-box-shadow: 0px 0px 60px 0px #111;
                -webkit-box-shadow: 0px 0px 60px 0px #111;
                -o-box-shadow: 0px 0px 60px 0px #111;
                box-shadow: 0px 0px 60px 0px #111;
                filter:progid:DXImageTransform.Microsoft.Shadow(color=#111, Direction=NaN, Strength=60);

                &_compteur {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    padding-bottom: 15px;

                    &_left {
                        width: 20px;
                        padding: 7px 0px;

                        &_bulle {
                            width: 15px;
                            height: 15px;
                            content: "";
                            border-radius: 15px;
                        }

                        .active {
                            background-color: #007BFF;
                        }
                    }

                    &_right {
                        padding: 0 5px;
                        display: flex;
                        flex-direction: column;

                        &_bpm {
                            font-size: 23px;
                            font-weight: 400;
                            color: white;
                            padding-left: 3px;

                            &_legend {
                                float:right;
                                font-size: 10px;
                                font-weight: 300;
                                padding-top:11px;
                                padding-right: 5px;
                            }
                        }

                        &_phases {
                            display: flex;
                            flex-direction: row;

                            &_beat, &_phase {
                                background-color: #292a2c;
                                width: 13px;
                                height: 5px;
                                margin-left: 3px;
                            }

                            &_beat.active {
                                background-color: white;
                            }

                            &_phase.active {
                                background-color: #007BFF;
                            }
                        }
                    }
                }
            }

            .text {
                text-align: center;
                margin: 20px 8%;
                color: white;
            }

            .list {
                list-style-type: none;
                display: flex;
                justify-content: space-evenly;

                &_item {
                    flex-direction: column;
                    align-items: center;
                    display: flex;
                    margin-top: 20px;

                    .icon {
                        background-color: #28282A;
                        height: 60px;
                        width: 60px;
                        border-radius: 15px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .bi {
                            font-size: 1.7rem;
                            height: 20px;
                            width: 20px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: #fff;
                        }
                    }

                    p {
                        margin: 10px 0;
                        color: white;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .SyncMusic-container {
        min-height: 40vh;
        &_center {
            padding-top: 50px;
            width: 90vw;
            &_left {
                /*display: flex;
                justify-content: center;*/
                display: none;
            }
            &_right {
                max-width: 100vw;
                width: 100%;
                padding: 0 0 50px 0;

                .title {
                    margin: 30px auto;
                    text-align: center;
                }

                .text {
                    text-align: center;
                }

                .list {
                    margin: 30px auto;

                    &_item {
                        .icon {
                            height: 50px;
                            width: 50px;

                            .bi {
                                font-size: 1.5rem;
                            }
                        }
                    }
                }

                h4 {
                    font-size: 14px;
                }
            }
        }
    }
}