@import "/src/variables.scss";

.Community-container {
    min-height: 200px;
    width: 100vw;
    background: rgb(2,135,227);
    background: linear-gradient(90deg, rgba(2,135,227,1) 0%, rgba(102,54,183,1) 53%, rgba(200,61,115,1) 100%);
    color: rgb(180, 180, 180);
    /*
    background: rgb(2,135,227);
    background: linear-gradient(60deg, rgba(93,248,254,1) 20%, rgba(78,6,204,1) 70%, rgba(225,21,180,1) 100%);
    color: rgb(180, 180, 180);
     */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px 0;

    h2 {
        color:#fff;
        text-shadow: none;
    }
    
    &_description {
        display: flex;
        flex-direction: column;

        .link {
            color: #fff;
            text-decoration: none;
            margin: 10px 0;
            font-size: 40px;
        }
    }

    &_btn {
        background-color: #000;
        color:#fff;
        padding: 20px 15px;
        border-radius: 12px;
        text-decoration: none;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: .8px;
        font-size: 20px;
    }
}

@media screen and (max-width: 700px) {
    .Community-container {
        padding: 10%;
        justify-content: center;
        flex-direction: column;
        &_description {
            margin-bottom: 40px;
            text-align: center;

            h2 {
                font-size: 30px;
            }

            .link {
                font-size: 30px;
            }
        }

        &_btn {
            font-size: 15px;
        }

    }
}