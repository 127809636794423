@import "/src/variables.scss";

.FixtureLibrary-container {
    width: 100vw;
    display: flex;
    justify-content: center;
    padding: 75px 0;
    padding-bottom: 25px;
    
    &_center {
        max-width: 1200px;
        width: 70vw;
        display: flex;
        flex-direction: column;

        .library {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            &_img {
                position: relative;
                flex: 2;
                text-align: center;

                .ipad {
                    width: 25vw;
                    margin-left: 50px;
                }
            }
            &_description {
                flex: 2;
                text-align: right;
                position:relative;

                h2 {
                    margin-bottom: 40px;
                    margin-top: 40px;
                }

                &_text {
                    margin-bottom: 30px;
                    margin-top: 20px;
                }

                &_label, &_labelmore {
                    border: 1px solid #fff;
                    border-radius: 20px;
                    color:white;
                    padding: 10px;
                    padding-left: 15px;
                    text-decoration: none;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    float: right;
                    margin-left: 10px;
                    margin-top: 10px;
                    font-size: 10px;
                }

                &_labelmore {
                    clear: both;
                    border: none;
                    color: rgb(152,152,152);
                }
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .FixtureLibrary-container {
        &_center {
            .library {
                &_description {
                    text-align: center;

                    &_label, &_labelmore {
                        float: left;
                        clear: none;
                        font-size: 10px;
                    }
                }
                &_img {
                    .ipad {
                        margin-left: 0;
                        width: 70vw;
                        margin-top: 50px;
                    }
                }
            }
        }
    }
}