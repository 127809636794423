.Contact-container {
    .Contact-container-center {
        min-height: 400px;
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        padding-top: 30px;
        padding-bottom: 30px;

        &_title {
            text-align: center;
            margin: 100px 0 50px 0;
            z-index: 1;
            padding-left: 15px;
            padding-right: 15px;
            background: rgb(2,135,227);
            background: linear-gradient(230deg, rgba(93,248,254,1) 40%, rgba(225,21,180,1) 70%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        &_form {
            width: 70vw;
            text-align: center;

            .success {
                margin-top: 60px;
                margin-bottom: 40px;
                padding: 20px;

                background: rgb(2,135,227);
                background: linear-gradient(90deg, rgba(2,135,227,1) 0%, rgba(102,54,183,1) 53%, rgba(200,61,115,1) 100%);
                color: rgb(180, 180, 180);
            }

            .form {
                margin-top: 25px;
                display: flex;
                flex-direction: row;
                justify-content: center;

                .left {
                    width: 25vw;
                    height: 100%;
                    padding: 25px;
                }

                .right {
                    width: 50vw;
                    height: 100%;
                    padding: 25px;
                }

                input {
                    width: 100%;
                    height: 50px;
                    margin-top: 15px;
                    background-color: #111;
                    padding: 10px;
                    color: white;
                }

                textarea {
                    width: 100%;
                    height: 250px;
                    margin-top: 15px;
                    background-color: #111;
                    padding: 10px;
                    color: white;
                }

                button {
                    width: 15vw;
                    background-color: #fff;
                    padding: 10px;
                    padding-left: 15px;
                    border-radius: 20px;
                    text-decoration: none;
                    text-transform: uppercase;
                    font-weight: 500;
                    letter-spacing: 1px;
                    color: #111;
                    word-break: keep-all;
                    height: 40px;
                    margin-top: 15px;
                    text-align: center;
                    cursor: pointer;
                }

                button:disabled {
                    opacity: 0.5;
                    cursor: default;
                }
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .Contact-container {
        .Contact-container-center {
            &_form {
                width: 90vw;

                .form {
                    flex-direction: column;

                    .left {
                        width: 100%;
                    }

                    .right {
                        width: 100%;
                    }

                    button {
                        width: 200px;
                    }
                }
            }
        }
    }
}