@import "/src/variables.scss";

.CustomizeDashboard-container {
    min-height: 50vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 50px 0;

    &_title {
        margin: 20px 0 0 0;
        text-align: center;
        transform: translateY(40px);
        opacity: 0;
        transition: 1s all ease-in-out;

        &.animate {
            transform: translateY(0px);
            opacity: 1;
        }
    }
    &_subtitle {
        margin: 20px 0 0 0;
        transform: translateY(40px);
        opacity: 0;
        transition: 1s all ease-in-out;

        &.animate {
            transform: translateY(0px);
            opacity: 1;
        }
    }
    &_text {
        margin: 40px 0 0 0;
        text-align: center;
        transform: translateY(40px);
        opacity: 0;
        transition: 1s all ease-in-out;

        &.animate {
            transform: translateY(0px);
            opacity: 1;
        }
    }

    &_dashboard {
        display: flex;
        width: 100%;
        align-items: center;
        padding-top: 50px;
        padding-bottom: 50px;
        justify-content: center;
    }

    &_list {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        padding: 25px 0;

        .item {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 300px;
            &_icon {
                height: 60px;
                width: 60px;
                background-color: $blueAccent;
                border-radius: 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                
                .bi {
                    font-size: 1.7rem;
                    height: 20px;
                    width: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                }
            }

            h4 {
                color: #fff;
                margin: 15px 0;
            }

            h5 {
                font-weight: 400;
                text-align: center;
                line-height: 1.5;
                font-size: 18px;
                margin: 0 7%;
            }
        }
    }
}


@media screen and (max-width: 700px) {
    .CustomizeDashboard-container {
        padding: 10% 10% 10% 10%;
        &_title {
            margin: 20px auto;
        }
        &_subtitle {
            margin: 20px 0 0 0;
        }
        &_text {
            margin: 10px 0 0 0;
        }
        &_list {
            padding: 0 0;
            .item {
                margin: 0 0 30px 0;
    
                h5 {
                    font-size: 16px;
                }
            }
        }
    }
}