@import "/src/variables.scss";

.Hardware-container {
    width: 100vw;
    display: flex;
    justify-content: center;
    padding: 50px 0;
    padding-bottom: 74px;
    
    &_center {
        max-width: 1200px;
        width: 70vw;
        display: flex;
        flex-direction: column;

        .interface {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            &_img {
                flex: 2;
                text-align: center;

                img {
                    width: 40%;
                    margin-right: auto;
                    margin-left: auto;
                    margin-top: 20px;
                }

                &_compatible {
                    width: 40%;
                    margin-right: auto;
                    margin-left: auto;
                    margin-top: -60px;

                    &_dmx {
                        border: 1px solid #fff;
                        border-radius: 20px;
                        color:white;
                        padding: 10px;
                        padding-left: 15px;
                        text-decoration: none;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                        width: 100%;
                        margin-top: 80px;
                        margin-bottom: 30px;
                        margin-left: auto;
                        margin-right: auto;
                    }

                    &_wifi , &_ethernet {
                        margin-bottom: 20px;
                        float: left;
                        height: 30px;
                        font-size: 13px;
                        width: 45%;
                        text-decoration: none;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                    }

                    &_ethernet {
                        width: 55%;
                    }

                    &_wifi .label, &_ethernet .label{
                        height: 35px;
                        vertical-align: middle;
                        font-weight: 500;
                        margin-left: 0px;
                        display: block;
                    }

                    &_wifi svg, &_ethernet svg {
                        font-size: 25px;
                        color: white;
                        height: 25px;
                    }
                }
            }
            &_description {
                flex: 2;

                h2 {
                    margin-bottom: 40px;
                }

                .text {
                    margin-bottom: 50px;
                    margin-top: 20px;
                }

                .button {
                    background-color: #fff;
                    padding: 10px;
                    padding-left: 15px;
                    border-radius: 20px;
                    text-decoration: none;
                    text-transform: uppercase;
                    font-weight: 500;
                    letter-spacing: 1px;
                    color: #111;
                    width: 147px;
                    word-break: keep-all;
                    cursor: pointer;
                }
            }
        }
    }
}

.modal {
    background-color: #111111!important;

    .close {
        float: right;
        color:white;
        font-size: 30px;

        background-color: transparent;
        border: 0;
        cursor: pointer;
    }

    h2 {
        margin-bottom: 30px;
    }

    .item {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 150px;

        .image {
            width: 15vw;
            display: flex; /* contexte sur le parent */
            flex-direction: column; /* direction d'affichage verticale */
            justify-content: center; /* alignement vertical */

            img {
                width: 90px;
            }
        }

        .text {
            width: 70vw;
            padding: 30px 30px;
            display: flex; /* contexte sur le parent */
            flex-direction: column; /* direction d'affichage verticale */
            justify-content: center; /* alignement vertical */

            h2 {
                font-size: 30px;
                margin-bottom: 10px;
            }

            h3 {
                font-weight: 300;
            }

            p {
                color: #007BFF;
            }
        }

        .button {
            width: 15vw;
            background-color: #fff;
            padding: 10px;
            padding-left: 15px;
            border-radius: 20px;
            text-decoration: none;
            text-transform: uppercase;
            font-weight: 500;
            letter-spacing: 1px;
            color: #111;
            word-break: keep-all;
            height: 40px;
            margin-top: 55px;
            text-align: center;
        }
    }
}

@media screen and (max-width: 1220px) {
    .Hardware-container {
        &_center {
            .interface {
                &_img {
                    img {
                        width: 60%;
                    }
                    &_compatible {
                        width: 60%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .modal {
        .item {
            height: 130px;
            .text {
                margin-left: 0;
                padding-left: 0;
            }

            .image {
                display: none;
            }

            .button {
                width: 130px;
                margin-top: 30px;
                text-align: center;
            }
        }
    }

    .Hardware-container {
        &_center {
            width: 80vw;
            text-align: center;
            .title {
                font-size: 26px;
            }

            .interface {
                flex-direction: column-reverse;
                &_description {
                    .text {
                        font-size: 16px;
                    }
                }

                &_img {
                    margin-top: 40px;
                }
            }
        }
    }
}