@import "/src/variables.scss";

.Header {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100vw;
    height: 100px;
    position: absolute;
    z-index: 100;
    
    .Logo {
        display: flex;
        align-items: center;
        z-index: 2;

        a {
            text-decoration: none;
        }

        &_img {
            height: 35px;
            width: 35px;
            margin: 0 15px 0 20px;

            img {
                width: 100%;
            }
        }

        &_title {
            color: white;
            position: relative;
            font-size: 30px;
            &::after {
                content: "for iOS";
                position: absolute;
                right: 0;
                top: -10px;
                color: $greyText;
                font-size: 14px;
                font-weight: 400;
                transform: translateX(100%);
            }
        }
    }

    .Nav-links {
        list-style-type: none;
        display: flex;

        &_link {
            color: #fff;
            margin: 0 20px;
            text-decoration: none;
            font-size:15px;
        }
    }

    &_mobile {
        display: none;
    }

    &_menu {
        display: none;
    }
}

@media screen and (max-width: 700px) {
    .Header {
        .Logo {
            position: absolute;
            left: 0;
        }
        .Nav-links {
            display: none;
        }

        &_menu {
            position: absolute;
            top: 45px;
            right: 30px;
            height: 18px;
            width: 23px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            cursor: pointer;

            .top-bar {
                top: 0;
                right: 25%;
                border-radius: 5px;
                position: relative;
                height: 2px;
                width: 100%;
                background-color: #fff;
                transform-origin: right top;
                transition: transform .15s ease-in-out;

                &.open {
                    transform: rotate(-45deg);
                }
            }

            .middle-bar {
                position: relative;
                height: 2px;
                width: 100%;
                background-color: #fff;
                transition: opacity .15s ease;

                &.open {
                    opacity: 0;
                }
            }

            .bottom-bar {
                bottom: 0;
                right: 25%;
                position: relative;
                height: 2px;
                width: 100%;
                background-color: #fff;
                transform-origin: right bottom;
                transition: transform .15s  ease-in-out;

                &.open {
                    transform: rotate(45deg);
                }
            }
        }
        
        &_mobile {
            position: absolute;
            top: 0;
            height: 230px;
            width: 100%;
            background-color: #101010;
            transition: transform .3s ease;
            transform: translateY(-100%);
            display: flex;
            align-items: flex-end;

            .links {
                list-style-type: none;
                li {
                    margin: 30px;
                    .link {
                        text-decoration: none;
                        color: #fff;
                        font-size: 28px;
                        font-weight: 600;
                    }
                }
                
            }

            &.open {
                transform: translateY(0);
            }
        }
    }
}