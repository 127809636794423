@import "/src/variables.scss";

.ControlLights-container {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5vw;

    &_title {
        margin: 20px 0 0 0;
        text-align: center;
        transform: translateY(40px);
        opacity: 0;
        transition: 1s all ease-in-out;

        &.animate {
            transform: translateY(0px);
            opacity: 1;
        }
    }
    &_subtitle {
        margin: 20px 0 0 0;
        transform: translateY(40px);
        opacity: 0;
        transition: 1s all ease-in-out;

        &.animate {
            transform: translateY(0px);
            opacity: 1;
        }
    }
    &_text {
        margin: 40px 0 0 0;
        text-align: center;
        transform: translateY(40px);
        opacity: 0;
        transition: 1s all ease-in-out;

        &.animate {
            transform: translateY(0px);
            opacity: 1;
        }
    }
    &_list {
        max-width: 1000px;
        min-width: 330px;
        margin: 5vh 0;
        list-style-type: none;
        display: flex;
        justify-content: space-evenly;
        /*flex-wrap: wrap;*/

        &__item {
            width: 33%;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
                position: absolute;
                width: 100%;
                // height: 100%;
            }
            
            &___card {
                height: 240px;
                width: 180px;
                border-radius: 12px;
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;

                h4 {
                    flex: 1;
                    position: absolute;
                    bottom: 10%;
                }

                &.--group {
                    background: rgb(25,48,66);
                    background: linear-gradient(90deg, rgba(25,48,66,1) 0%, rgba(24,53,68,1) 100%);
                }
                &.--preset {
                    background: rgb(69,27,59);
                    background: linear-gradient(90deg, rgba(69,27,59,1) 0%, rgba(72,29,61,1) 100%);
                }
                &.--sequence {
                    background: rgb(38,30,66);
                    background: linear-gradient(90deg, rgba(38,30,66,1) 0%, rgba(39,31,69,1) 100%);
                }
            }

            h5 {
                margin: 30px 5%;
                text-align: center;
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .ControlLights-container {
        padding: 0 10% 10% 10%;
        &_title {
            margin: 20px auto;
        }
        &_subtitle {
            margin: 20px 0 0 0;
        }
        &_text {
            margin: 10px 0 0 0;
        }

        &_list {
            &__item {
                &___card {
                    width: 120px;
                    height: 170px;
                }

                h5 {
                    margin: 10px;
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .ControlLights-container {
        &_list {
            &__item {
                &___card {
                    width: 100px;
                    height: 150px;
                }

                h4 {
                    font-size: 15px;
                }

                h5 {
                    margin: 10px;
                }
            }
        }
    }
}